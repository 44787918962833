import DashboardLoadingPage from '@/components/DashboardLoadingPage'
import React from 'react'
import { getOrganization } from 'network/lib/organization'
import { useRouter } from 'next/router'
import sanitizeUrl from '@/lib/sanitizeUrl'

const Loading = () => {
  // Get onSuccessRedirectUrl from query params
  const router = useRouter()
  React.useEffect(() => {
    const onSuccessRedirectUrl = sanitizeUrl(
      new URLSearchParams(window.location.search || '').get('onSuccessRedirectUrl') || ''
    )
    if (onSuccessRedirectUrl) {
      // Ping every 2 seconds
      const interval = setInterval(() => {
        getOrganization()
          .then(() => {
            clearInterval(interval)
            router.push(onSuccessRedirectUrl)
          })
          .catch(() => {
            // Do nothing
          })
      }, 2000)
    }
  }, [])
  return <DashboardLoadingPage />
}

export default Loading
